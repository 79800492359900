<template>
  <ftp-file
    :key="getID('ftp-file-retriever')"
    :uuid="uuid"
    i18nKey="ftp_list_files"
    :middlewareType="middlewareType"
    :middlewareID="middlewareID"
    v-model="value"
    :ftpFileData="ftpFileData"
    :renderList="renderList"
    :defaultAgentActionID="defaultAgentActionID"
    :defaultAgentBlockID="defaultAgentBlockID"
    @saved="emitSaved"
    @close="$emit('close')"
  />
</template>

<script>

import FtpFile from './FTPFile.vue'
import {v4 as uuidv4} from 'uuid'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

export default {
  components: {
    FtpFile
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uuidMap: {},
      middlewareType: new Middlewares().items.find(el=> el.id == 22),
      defaultAgentActionID: 3,
      defaultAgentBlockID: 1,

      renderList: [
        { label: 'Path', cols: "12", source: 'PATH'},
      ],

      
      ftpFileData: {
        path: undefined,
        connection: undefined
      },
      
    };
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    emitSaved(payload) {
      this.$emit("saved", payload);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
  },
};
</script>

<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}
</style>